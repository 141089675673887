.generalInformationValue {
    font-weight: 700;
    font-size: 14px;
    line-height: 19.07px;
    letter-spacing: 0%;
    color: rgba(0, 0, 0, 1)
}


.generalInformationLabel {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.34px;
    letter-spacing: 0%;
    color: rgba(154, 152, 152, 1)
}