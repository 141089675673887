@import 'business/App.scss';

$xxs-margin: 5px;
$xs-margin: 10px;
$sm-margin: 20px;
$md-margin: 40px;
$lg-margin: 80px;

$xs-margin-mobile: 4px;
$sm-margin-mobile: 8px;
$md-margin-mobile: 30px;
$lg-margin-mobile: 50px;

@mixin horizontalSize($margin, $mobileMargin) {
  & > *:not(:last-child) {
    margin-right: $margin;
    @include mobile {
      margin-bottom: $mobileMargin;
    }
  }
}

@mixin verticalSize($margin, $mobileMargin) {
  & > *:not(:last-child) {
    margin-bottom: $margin;
    @include mobile {
      margin-bottom: $mobileMargin;
    }
  }
}

.spaceContainer {
  display: flex;
  min-width: 0; // Fix for flex scheananigans : https://dfmcphee.com/flex-items-and-min-width-0
  
  &.fullWidth {
    width: 100%;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
    & > .spaceContainer {
      width: auto;
    }
  }

  &.justify-space-around {
    justify-content: space-around;
    & > .spaceContainer {
      width: auto;
    }
  }

  &.justify-space-between {
    justify-content: space-between;
    & > .spaceContainer {
      width: auto;
    }
  }

  &.wrap{
    flex-wrap: wrap
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-baseline {
    align-items: baseline;
  }

  &.horizontal {
    flex-direction: row;

    &.size-xxsmall {
      @include horizontalSize($xxs-margin, $xs-margin-mobile);
    }

    &.size-xsmall {
      @include horizontalSize($xs-margin, $xs-margin-mobile);
    }
    
    &.size-small {
      @include horizontalSize($sm-margin, $sm-margin-mobile);
    }

    &.size-middle {
      @include horizontalSize($md-margin, $md-margin-mobile);
    }

    &.size-large {
      @include horizontalSize($lg-margin, $lg-margin-mobile);
    }

    &.size-no-space {
      @include horizontalSize(0, 0);
    }
  }

  &.vertical {
    flex-direction: column;

    &.size-small {
      @include verticalSize($sm-margin, $sm-margin-mobile);
    }

    &.size-middle {
      @include verticalSize($md-margin, $md-margin-mobile);
    }

    &.size-large {
      @include verticalSize($lg-margin, $lg-margin-mobile);
    }

    &.size-no-space {
      @include verticalSize(0, 0);
    }
  }
}
