@import 'ui/variables.module.scss';

#root {
  width: 100%;
}

html,
body {
  font-family: $primaryFont;
  color: $textColor;
  margin: 0;
  background-color: #FBFBFB;
}

.separator {
  height: 16px;
}

.margin-auto {
  margin: auto;
}

//
// EFFECTS
//
.elevation1 {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.elevation2 {
  @include elevation2;
}

.innerShadow1 {
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.smallMargin {
  margin: 10px;
}

//
// COLOR CLASSES
//

//
// CUSTOM STYLES
//
.menuLink {
  color: $black;
  height: fit-content;
}

.headerTitle {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0px;
  line-height: 27.24px;
  letter-spacing: 0%;
  color: rgba(12, 13, 14, 1);
}

.subTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $primaryHighlight;
  margin-bottom: 15px;
}

.tableTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 15px;
}

.headerContainer {
  gap: 10px;
}

.tableLabel {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $black1;
  margin: 0;
}

.card {
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: $black1;
    margin-top: 0;
  }
}

.smallMarginTop {
  margin-top: 40px;
}

.smallMarginBottom15 {
  margin-top: 15px;
}



.noStyle {
  margin: 0;
}


.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.smallMarginRight {
  margin-right: 5px;
}

.pointerCursor {
  cursor: pointer !important;
}

.configLink {
  margin: 5px;
  cursor: pointer;
  text-decoration: none;
  color: $primaryHighlight;

  span {
    margin-right: 10px;
  }
}


.titleRedirect {
  color: $primaryHighlight;
  text-transform: uppercase;
}

.searchBar {
  input {
    background-color: rgba(230, 236, 247, 1);
  }

  width: 20%;
}


.hidden {
  display: none;
}

.flexDisplay {
  display: flex;
}

.flex1 {
  flex: 1;
}

.flex-end {
  align-self: flex-end;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-vertical .ant-form-item-label {
  padding: 8px 0 0 0;
}

.ant-table-content table {
  table-layout: fixed !important;
}

.ant-table-tbody>tr>td {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
  font-size: 12px !important;
}

.ant-table-thead>tr>th {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.ant-table-column-sorter {
  margin: 0px !important;
}

.ant-table-column-title {
  margin: 0px !important;
  font-size: 12px;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ant-descriptions {
  width: 100%;
}

.ant-carousel,
.ant-carousel>* {
  height: 100%;
  overflow: hidden;

  .slick-slider {
    height: 100%;


    .slick-track {
      height: 100%;

      .slick-slide div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .slick-list,
  .slick-list>* {
    height: 100%;
  }
}

.ant-image {
  height: 100%;

  img {
    height: 100%;

  }
}

.ant-input-affix-wrapper {
  background: transparent;
}

.searchInput {
  background: $gradiant1;
  border-radius: 10px;

  & input {
    background: transparent;
  }
}

.iconFontSize {
  font-size: 18px;
}

.whiteSpaceNoBreak {
  white-space: nowrap;
}



.descriptionCards {
  flex-wrap: wrap;
  align-items: stretch;
}

.descriptionCard {
  margin-right: 50px;
  width: 615px;
  border: 1px solid $primaryHighlight;
}

.secondaryButton {
  .ant-btn {
    background-color: $primaryHighlight;
    border-color: $primaryHighlight;
  }
}

.detailResourceContainer {
  background: linear-gradient(288.71deg,
      rgba(0, 65, 180, 0.2) 0%,
      rgba(140, 197, 203, 0.2) 52.01%);
  border-radius: 24px;
  padding: 20px;
}

.overrideMargin0 {
  margin: 0px !important;
}

.marginTop20 {
  margin-top: 20px;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.margin10 {
  margin: 10px;
}

.padding10 {
  padding: 10px;
}

.padding5 {
  padding: 5px;
}

.marginBottom50 {
  margin-bottom: 50px;
}

.textAlignCenter {
  text-align: center;
}

h1 {
  margin-top: 0;
  font-weight: 500;
}

.searchButton {
  background-color: $primaryHighlight;
  border-color: $primaryHighlight;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100%;
  flex: 1;
}

.tabsContainer {
  height: 100%;
  min-height: 500px;
}




.custom-search-bar {
  width: 100%;
  position: relative;
  z-index: 2;
  /* Met l'input au-dessus */
  background: linear-gradient(90deg, #E8F4F5 0%, #D3E0F1 100%);
  box-shadow: 3px 4px 13px 2px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  color: $blue10;
  font-weight: 700;
  font-size: $fontSize;

  input {
    width: 100%;
    position: relative;
    z-index: 2;
    /* Met l'input au-dessus */
    background: linear-gradient(90deg, #E8F4F5 0%, #D3E0F1 100%);
    box-shadow: 3px 4px 13px 2px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: $blue10;
    font-weight: 700;
    font-size: $fontSize;
  }

  input::placeholder {
    color: $blue10;
    font-weight: 600;
    font-size: $fontSize;
  }
}



.custom-search-bar-header {
  border-radius: 100px;
  background: linear-gradient(90deg, #E8F4F5 0%, #D3E0F1 100%);

  input {
    border-radius: 100px;
    background: linear-gradient(90deg, #E8F4F5 0%, #D3E0F1 100%);
  }
}


.custom-search-bar-header {
  border-radius: 100px;
  background: linear-gradient(90deg, #E8F4F5 0%, #D3E0F1 100%);

  input {
    border-radius: 100px;
    background: linear-gradient(90deg, #E8F4F5 0%, #D3E0F1 100%);
  }
}

.ant-layout .ant-layout-sider-trigger {
  background-color: #FBFBFB;
  width: 'fit-content' !important;

  & span {
    color: $blue10;
    font-weight: 700;
  }
}

.ant-layout-sider {
  width: auto;
}

// CSS: titre du dossiee

.caseCountryTitle {
  font-family: $primaryFont;
  font-weight: 400;
  font-size: 20px;
  line-height: 27.24px;
  letter-spacing: 0%;
  margin-bottom: 0;
}

.caseTitle {
  font-weight: 400;
  font-size: 18px;
  color: $primaryHighlight;
  line-height: 16.34px;
  margin: 0;
  letter-spacing: 0%;
}


.caseClaimTitle {
  font-weight: 700;
  font-size: 18px;
  color: $primaryHighlight;
  line-height: 16.34px;
  margin: 0;
  letter-spacing: 0%;
  margin: 20px;
}

// CSS: Section timeline

.timelineSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 4.6px 0px #00000038;
  border-radius: 12px;
  padding: 20px;
}

.timelineTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 19.07px;
  letter-spacing: 0%;
  color: #000000;
}


// CSS: CASE DETAIL CARD

.caseDetailCard {
  width: 100%;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  background-color: #FBFBFB;
}

.caseDetailCardFullWidth {
  width: 100%;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  background-color: #FBFBFB;
}

.caseDetailImage {
  width: 20%;
  height: 100%;
}

.generalInformationLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  letter-spacing: 0%;
  color: rgba(154, 152, 152, 1)
}

.generalInformationValue {
  font-weight: 700;
  font-size: 14px;
  line-height: 19.07px;
  letter-spacing: 0%;
  color: rgba(0, 0, 0, 1)
}

// CSS CASE NOTE SECTION

.noteSection {
  border-radius: 12px;
  box-shadow: 0px 1px 4.6px 0px rgba(0, 0, 0, 0.22);
  background: rgba(255, 255, 255, 1);

}

.sectionTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 19.07px;
  letter-spacing: 0%;
  color: rgba(107, 107, 107, 1);
}

.noteDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  letter-spacing: 0%;
  color: rgba(150, 150, 150, 1);
}

.noteUser {
  font-weight: 700;
  font-size: 12px;
  line-height: 16.34px;
  letter-spacing: 0%;
  color: rgba(12, 13, 14, 1);
}

.noteInput {
  border-radius: 8px;
  padding-top: 5px;
  padding-right: 12px;
  padding-bottom: 5px;
  padding-left: 12px;
  margin-right: 10px;
  background: rgba(248, 247, 247, 1);
}




.ant-tooltip-inner {
  color: $black;
  box-shadow: none !important;
  border: none !important;
}


.cardInformationContainer {
  width: 100px;
  height: 90px;
  border-radius: 8px;
  gap: 6px;
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4.6px 0px #00000038;
}

.cardInformationTotal {
  color: #00A9B4;
  font-weight: 700;
  font-size: 18px;
  line-height: 24.51px;
  letter-spacing: 0%;
}

.cardInformationLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.34px;
  letter-spacing: 0%;
  color: #0C0D0E;
}


.__react_component_tooltip {
  z-index: 9999 !important;
}