@import 'business/App.scss';


.mainLayout {
  background-color: #FBFBFB;
  min-height: 100vh;
}

.header {
  padding: 0;
  background-color: #FFFFFF;
  padding-inline: 40px !important; // Override antd
}

.headerContent {
  margin: 0px 16px;
}


.content {
  @include desktop-max-width;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;

  @include mobile {
    padding: 0 0 20px 0;
  }

  &.connected {
    padding: 0 40px 40px 40px;
  }
}

.layout {
  min-height: 100vh;
  background-color: $white;
}

.footer {
  @include mobile {
    padding: 20px;
  }
}


.sidebarItem {
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  color: #0C0D0E;
  vertical-align: middle;
}



.logoSection {
  height: fit-content;
  display: flex;
  align-items: center;
}


.logoutText {
  color: $red;
  font-size: 18px !important;
  border: none !important;
  padding: 0 20px !important;

  &::after {
    border-bottom: none !important;
  }

  &::after:hover {
    border-bottom: none !important;
  }
}

.userIcon {
  color: white;
  background: linear-gradient(285.9deg, #0041B4 -32.66%, #8CC5CB 81.65%);
  border-radius: 50%;
  font-size: 20px;
  width: 25px;
  height: 25px;
}

.userName {
  color: #6B6B6B;
  font-weight: 400;
  font-size: 14px;

  @include mobile {
    display: none !important;
  }
}


.menuDropdownButton {
  height: 35px;
  background-color: #FBFBFB;
  border-radius: 100px;
  box-shadow:
    0px 4px 6px rgba(0, 0, 0, 0.1),
    0px 1px 3px rgba(0, 0, 0, 0.06);
}

.footerCollapse {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* S’adapte à collapsedWidth */
  height: 50px;
  /* Ajuste la hauteur */
  border-top: 1px solid #e0e0e0;
  cursor: pointer;
}

.sidebar {
  overflow: auto !important;
  height: 100vh !important;
  position: sticky !important;
  top: 0;
  bottom: 0;

  :global(.ant-menu) {
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 48px;
  }

  :global(.ant-menu-inline-collapsed > .ant-menu-item) {
    padding-inline: calc(50% - 24px);
  }

  :global(.ant-menu-item) {
    min-height: 40px;
  }

  .icon {
    width: 40px;
    height: 40px;
    padding-top: 6px;
    padding-right: 8px;
    padding-bottom: 6px;
    padding-left: 8px;
    gap: 8px;
    border-radius: 4px;
    background: #EEEEEE;

    :global(.anticon) {
      display: flex;
    }

    .iconText {
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 0%;
      text-align: center;
      vertical-align: middle;
      color: #0C0D0E;
    }
  }

  :global(.ant-menu-title-content) {
    margin-inline-start: 10px;
    color: #0c0d0e;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 100%;
  }
}