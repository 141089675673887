.filtersButton {
    background: linear-gradient(288.25deg, rgba(0, 65, 180, 0.2) 4.73%, rgba(140, 197, 203, 0.2) 52.02%);
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #0C0D0E
}

.editColumnsButton {
    border-radius: 5px;
    background-color: #E6ECF7;
    color: #0041B4;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
}


.filtersButtonContainer {
    position: sticky;
    bottom: 0;

    button {
        width: 100%;
    }
}