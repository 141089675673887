@import 'ui/variables.module.scss';

.menuLink {
  color: $black;
  height: fit-content !important;
  font-size: 18px !important;
  font-weight: normal;
  margin: 0px;
  padding: 0 20px 0 20px !important

}
