@import 'business/App.scss';

.title {
  color: black;
  font-size: 20px;
  text-align: center;
}

.description {
  text-align: center;
}

.form{
  width: 100%;
}

.submitButton {
  margin-top: 30px;
}