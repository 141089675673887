@import 'ui/variables.module.scss';

.container {
    flex-wrap: wrap;
}


.cardContainer {
    margin-top: 20px
}

.inputFormItem {
    width: 40%;
}


.card {
    margin: 1%;
    width: 33%;
    background: $white1;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}