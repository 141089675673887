@import 'ui/variables.module.scss';

.tableContainer {
  background: $white1;
  border-radius: 5px;
}

.filterButton {
  background-color: $primaryHighlight;
  border-color: $primaryHighlight;
}

.table {
  thead th {
    background-color: white;
    border: none;

    color: #0c0d0e !important;
    font-weight: 700 !important;

    div {
      display: flex;
      flex-direction: row-reverse;
      color: $black1;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      span {
        margin-right: 5px;
      }
    }
  }
  .tableRowLight {
    background-color: $white2;
  }
  tbody tr {
    color: $black1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}
