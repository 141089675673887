.overlay {
  position: relative;

  .errorMessage {
    background: rgba($color: #ffffff, $alpha: 0.8);
    position: absolute;
    z-index: 800;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
