@import 'business/App.scss';

.title {
  color: black;
  font-size: 20px;
  margin-bottom: 0px;
  text-align: center;
}

.successIllustration {
  display: flex;
  justify-content: center;
}

.hint {
  font-size: 16px;
  color: $tundora;
  margin-bottom: 0px;
  align-self: start;
}
