@import 'ui/variables.module.scss';

.tag {
  background-color: $lightBlue;
  color: $primaryHighlight;
  border-radius: 22px;
  padding: 4px 16px;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
}