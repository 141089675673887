.primaryButton {
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    letter-spacing: 0%;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.secondaryButton {
    width: 212;
    border-radius: 5px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    background-color: rgba(230, 236, 247, 1);
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    letter-spacing: 0%;
    text-align: center;
    color: rgba(0, 65, 180, 1);
}

.mailButton {
    background: #E6ECF7;
    border-radius: 5px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    color: #0041B4;

}