@import 'ui/variables.module.scss';


.checkboxGroup {
    margin: 1%;
    display: flex;
    flex-direction: column;
}


.addAccessTitle {
    color: $primaryHighlight;
}


