.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper > * {
  height: 100%;
}

.img {
  object-fit: contain;
  height: 100% !important;
}
