@import 'ui/variables.module.scss';

.logoContainer {
    position: relative;
    width: 100%;
    max-width: 700px;
}


@media screen and (max-width: 992px) {
    .searchBar {
        font-size: 10px;
    }
}

.searchBar {
    width: 100%;
    box-shadow: 3px 4px 13px 2px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
}

.homeLogo {
    position: absolute;
    z-index: 1;
    width: 100%;
}

.searchIcon {
    color: $primaryHighlight
}