//
// COLORS
//
$white1: #FCFCFC;
$white2: #F4F8F9;
$white3: rgba(230, 236, 247, 1);
$grey1: #ffffff;
$grey3: #f5f5f5;
$grey4: #e8e8e8;
$grey6: #bfbfbf;
$grey8: #595959;
$white: $grey1;
$grey: #302e2e;
$black: #000000;
$blue5: #40a9ff;
$blue9: #003a8c;
$blue10: #0041B4;
$tundora: #484848;
$black1: #0C0D0E;
$red: #D2322D;

$searchShadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);

$gradiant1: linear-gradient(90deg, #E8F4F5 0%, #D3E0F1 100%);

$primaryHighlight: #00A9B4;
$lightBlue: #EBF4F5;

//
// INTENTIONS
//
$textColor: #0C0D0E;

//
// FONT FAMILIES
//
$primaryFont: 'Open Sans', sans-serif;

//
// VARIABLES
//
// 130px : padding general
// 13px: unité de spacing generale

:export {
  colorGrey1: $grey1;
  colorGrey3: $grey3;
  colorBlue9: $blue9;
  colorBlue5: $blue5;
}

$fontSize: 14px;

//
// DIMENSIONS
//
$desktop-max-width: 2500px;

//
// MIXINS
//
@mixin desktop-max-width {
  max-width: $desktop-max-width;
}

@mixin elevation2 {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

/*--------------------------------------------------------------
- Media queries: Base mixins for responsive breakpoints
--------------------------------------------------------------*/
$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 1024px;
$large-desktop-width: 1200px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin under-desktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin under-large-desktop {
  @media (max-width: #{$large-desktop-width - 1px}) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}