@import 'ui/variables.module.scss';

.mosaicCard {
    transition: transform .2s ease;
    border-radius: 5px;
    box-shadow: 0, 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: rgba(252, 252, 252, 1);
    height: 223px;
}




.mosaicCard:hover, .mosaicCardLight:hover {
    transform: scale(1.05); 
    transform-origin: center; 
}

