@import 'business/App.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  @include mobile {
    height: 100%;
    width: 100%;
    align-items: flex-start;
  }
}
