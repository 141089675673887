@import 'ui/variables.module.scss';

.numberTag {
    font-weight: bold; 
    font-size: 34px;
    text-align: center;
    color: $primaryHighlight;
    max-width: 20px;
  }
  

  .cardContainer {
    max-width: 100%; 
    max-height: 450px; 
    overflow: auto;
  }


  .classDetail {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }


  .trademarkName {
    padding: 5px 10px;
    border: solid;
  }
  