.iconStatus {
    border-radius: 50%;
    background-color: red;
    &.success {
      background-color: #52c41a;
    }
    &.availableToRenew {
      background-color: #90EE90;
    }
    &.toRenewBeforeEnd {
      background-color: #FFA502;
    }
    &.dueDateExpired {
      background-color: #f27979;
    }
}
  