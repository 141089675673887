@import 'business/App.scss';

.menuLink {
  @include mobile {
    padding: 0;
  }
}

.appLogo {
  aspect-ratio: initial;
  width: 100px;
}
