

.availableToRenew {
    background-color: #90EE90;
}


.toRenewBeforeEnd {
    background-color: #FFA502;
}

.dueDateExpired {
    background-color: #f27979;
}



.withConfirmationReceipt {
    background-color: #c9ffb2;
}

.withoutConfirmationReceipt {
    background-color: #ffbf00;;
}

.processed {
    background-color: #92bbf9;
}