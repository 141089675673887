@import 'ui/variables.module.scss';

.breadcrumbLoading {
  width: 120px !important;
  background: transparent !important;
}

.link {
  margin: 5px;
  cursor: pointer;
  text-decoration: none;
  color: $primaryHighlight;
}