.center,
div.center p {
  text-align: center;
}

.emphase {
  font-weight: bold;
}

.soften {
  font-weight: 300;
}

.noWrap {
  white-space: nowrap;
}

p.p {
  margin-bottom: 0;
  white-space: pre-line;
}

.preLine {
  white-space: pre-line;
}
