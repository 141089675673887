.whoIsText {
  white-space: pre-wrap;
  overflow-y: scroll;
  max-height: 500px;
}

.whoIsButton {
  display: flex;
  align-items: center;
  font-weight: 700;
  text-decoration: underline;
  padding: 0 !important;

  p {
    text-decoration: underline;
    margin-bottom: 0;
  }
}
