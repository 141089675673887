@import 'business/App.scss';

.card {
  padding: 24px;
  box-shadow: 0px 4px 12px rgba($tundora, 0.25);
  background: $white;
  border-radius: 5px;

  &.fixedWith {
    width: 470px;
  }

  @include mobile {
    width: 100%;
    box-shadow: none;
  }
}

.title{
  color: #00A9B4;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}