@import 'ui/variables.module.scss';

.status {
  margin-left: 10px;
}

.parent {
  padding: 24px;
  @include mobile {
    flex-direction: column-reverse !important;
  }

  .mapStatusContainer {
    flex: 1;
    min-width: 200px;
    gap: 25px;
  }

  .mapLegendContainer {
    flex: 3;

    .mapContainer {
      flex: 0 0 80%;
      position: relative;

      .tooltip {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .buttons {
        margin: 10px;
        gap: 10px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;
      }
    }

    .description {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      margin-top: 5px;

      p {
        margin-bottom: 5px;
      }
    }
  }
}


.modal {
  .mapContainer {
    width: 100%;
  }

  .description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-top: 5px;

    p {
      margin-bottom: 5px;
    }
  }
}