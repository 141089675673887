@import 'business/App.scss';

.title {
  color: black;
  font-size: 20px;
  margin-bottom: 0px;
  text-align: center;
}

.description {
  margin-bottom: 0px;
  align-self: start;
}
